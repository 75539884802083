<template>
  <div>
    <v-divider></v-divider>
    <!--<div class="mt-5 text-h6">{{language.componentLabel.labelBase}}</div>-->
    <div class="mt-5"></div>
    <lineFieldsArray
        :fieldAttrRow="{}"
        :fields="fields"
        :templateContent="{}"
        :value="pairArray"
        @line-field-input="checkInput(...arguments)"
        :headerTitle="false"
        :addable="true"
    ></lineFieldsArray>
    <input type="hidden" :value="JSON.stringify(this.localValue)" :name="field"/>
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import lineButtons from "@/cfg/lineButtons.json";

export default {
  props: ["result", "fieldAttributes", "field", "value"],
  components: {lineFieldsArray},
  data() {
    return {};
  },

  computed: {
    fields() {
      return {
        value: {
          type: "text",
          name: 'Base',
          fieldAttrInput: this.fieldAttributes.fieldAttrInput,
        },
        remove: lineButtons.remove,
      };
    },
    localValue() {
      let value = {bases: [""]};
      if (typeof this.value != "undefined") {
        value = JSON.parse(this.value);
      }
      if (value.bases.length === 0) {
        value.bases.push("");
      }
      return value;
    },
    pairArray: {
      get() {
        let pairArray = [];

        if (
            typeof this.localValue != "undefined" &&
            Object.keys(this.localValue).length !== 0
        ) {
          console.log(this.localValue);
          this.localValue.bases.forEach((value) => {
            pairArray.push({value: value});
          });
        } else {
          pairArray = [{value: ""}];
        }
        return pairArray;
      },
      set(newValue) {
        console.log("new value", newValue);
        this.$emit("input", JSON.stringify(newValue));
      },
    },
  },
  methods: {
    checkInput(value) {
      console.log(value);
      let sentValue = [];
      let update = true;
      // let caller = this;
      value.forEach(function (info) {
        if (typeof sentValue[info.value] != "undefined") {
          update = false;
          return;
        }
        sentValue.push(info.value);
      });

      if (update) {
        if (sentValue.length === 0) {
          sentValue.push("");
        }
        this.$set(this.localValue, "bases", sentValue);
        this.$emit("input", JSON.stringify(this.localValue));
      }
    },
  },
};
</script>


